.backgroundColorGrey {
    background-color: #eeeeee;
}

.backgroundColorSand {
    background-color: #f4ece6;
}

.backgroundColorWhite {
    background-color: white;
}

.boxShadow {
    box-shadow: rgba(0, 0, 0, 0.50) -11px 11px 17px 0;
}

.fontSize_0_8em {
    font-size: 0.8em;
}

.fontSize_0_9em {
    font-size: 0.9em;
}

.fontSize_1_2em {
    font-size: 1.2em;
}

.fontStyleNormal {
    font-style: normal;
}

.fontWeight_600 {
    font-weight: 600;
}

.fontWeightBold {
    font-weight: bold;
}

.height_48 {
    height: 48px
}

.height_250 {
    height: 250px;
}

.justifyContentBetween {
    justify-content: space-between;
}

.justifyContentEvenly {
    justify-content: space-evenly;
}

.marginBottom_8 {
    margin-bottom: 8px;
}

.marginBottom_16 {
    margin-bottom: 16px;
}

.marginLeft_8 {
    margin-left: 8px;
}

.marginLeft_20 {
    margin-left: 20px;
}

.marginTop_0 {
    margin-top: 0;
}

.marginTop_8 {
    margin-top: 8px;
}

.marginTop_16 {
    margin-top: 16px;
}

.marginTop_30 {
    margin-top: 30px;
}

.marginTop_48 {
    margin-top: 48px;
}

.minWidth_300 {
    min-width: 300px;
}

.minWidth_350 {
    min-width: 350px;
}

.overflowHidden {
    overflow: hidden
}

.padding_0_16_16_16 {
    padding: 0 16px 16px 16px;
}

.padding_8 {
    padding: 8px;
}

.padding_16 {
    padding: 16px;
}

.paddingLeft_16 {
    padding-left: 16px;
}

.paddingLeft_24 {
    padding-left: 24px;
}

.paddingTop_2 {
    padding-top: 2px;
}

.paddingTop_7 {
    padding-top: 7px;
}

.paddingTop_8 {
    padding-top: 8px;
}

.paddingTop_10 {

}

.paddingTop_12 {
    padding-top: 12px;
}

.paddingTop_150 {
    padding-top: 150px;
}

.positionAbsolute {
    position: absolute;
}

.textAlignCenter {
    text-align: center;
}

.textAlignJustify {
    text-align: justify;
}

.textAlignLeft {
    text-align: left;
}

.textAlignRight {
    text-align: right;
}

.textDecorationNone {
    text-decoration: none;
}

.textTransformUppercase {
    text-transform: uppercase;
}

.width_100_percent {
    width: 100%;
}
