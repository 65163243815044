.button {
    background-color: white;
    border: 2px solid #551a8b;
    border-radius: 20px;
    color: #551a8b;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 30px;
    text-transform: uppercase;
}

.button:hover {
    background-color: #551a8b;
    color: white;
}
