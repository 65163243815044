.fade-enter {
    opacity: 0;
    z-index: 1;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 2000ms;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 2000ms;
}
