.card {
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) -11px 11px 17px 0;
    padding: 20px;
    position: relative;
}
.faqContainerColumn {
    padding-left: 8px;
    padding-right: 8px;
}
h3 {
    color: #551a8b;
    margin-bottom: 20px;
    margin-top: 0;
    text-align: left;
}
.outerDiv {
    position: absolute;
    width: 100%;
}
